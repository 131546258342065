import React from 'react'
import Layout from 'components/Layout'
import PageHeader from "components/PageHeader"
import SeoComponent from "components/SeoComponent"

const pageHeader = {
  heading: 'Page Not Found',
  // subheading: '',
  style: 'jumbo'
}

const NotFoundPage = props => (
  <Layout>
    <SeoComponent title="404 Not Found" />
    <PageHeader fields={pageHeader} />
    <div className="container x-large" style={{ minHeight: '40vh' }}>
      <p className="lead">You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
